@import "./styles/font.css";
@import "./styles/container.css";
@import "./styles/tailwind.css";
@import "./styles/global.css";

/* src/index.css or any main css file */
@media (prefers-color-scheme: dark) {
  :root {
    --text: #FEFDF6;
    --background: #010C0F;
    --primary: #35DC32;
    --secondary: #093909;
    --accent: #047857;
    --button-primary: #C8EF9F;
    --button-secondary: #E0FAE0;
    --danger: #B64A4A;
    --warning: #b6904a;
    --success: #58b64a;
    --info: #4a4fb6;
    --gradient: linear-gradient(180deg, #093909 0%, #35DC32 100%);
    --border: hsl(var(--border));
    --input: hsl(var(--input));
    --ring: hsl(var(--ring));
    --foreground: hsl(var(--foreground));
    --destructive: hsl(var(--destructive));
    --destructive-foreground: hsl(var(--destructive-foreground));
    --muted: hsl(var(--muted));
    --muted-foreground: hsl(var(--muted-foreground));
    --popover: hsl(var(--popover));
    --popover-foreground: hsl(var(--popover-foreground));
    --card: hsl(var(--card));
    --card-foreground: hsl(var(--card-foreground));
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.radial-background {
  mask-image: radial-gradient(circle, white 50%, black 100%);
}

.radial-overlay {
  position: relative;
}

.radial-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,1) 100%);
  border-radius: 9999px; /* This makes the overlay rounded */
}

.radial-overlay img {
  border-radius: 9999px; /* This ensures the image has rounded corners as well */
}
